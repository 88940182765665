import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { List } from 'immutable';

import Loading from '^/components/app/content/Loading';

import { isPending } from '^/consts/responseStates';

export const ChecklistRecordGroupHistory = ({
  checklistHistory,
  checklistHistoryResponse,
  recordGroupId,
  onLoadMoreHistoryClick,
}) => {
  const results =
    checklistHistory && checklistHistory.get('results')
      ? checklistHistory.get('results')
      : List();
  const nextPage = checklistHistory && checklistHistory.get('next');

  // 1) True if we have no items yet and the API call is in progress
  const isLoadingInitial =
    isPending(checklistHistoryResponse) && results.count() === 0;

  // 2) True if we already have some items but are requesting more
  const isLoadingMore =
    isPending(checklistHistoryResponse) && results.count() > 0;

  if (isLoadingInitial) {
    return <Loading />;
  }

  return (
    <div>
      <h3 className="mt-1 mb-1">History</h3>
      <table className="responsive padded padded-sm mb-2">
        <thead>
          <tr>
            <th>Date</th>
            <th>Yes</th>
            <th>No</th>
            <th>N/A</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {results.count() === 0 && (
            <tr>
              <td colSpan="5">No history to display</td>
            </tr>
          )}
          {results.map(history => {
            if (history.get('status') === 'NOT_SUBMITTED') {
              return (
                <tr key={history.get('date_id')}>
                  <td>{moment(history.get('date_id')).format('DD-MM-YYYY')}</td>
                  <td colSpan="3">Nothing submitted</td>
                  <td>
                    <Link
                      className="base-font-color"
                      to={`/page/tools/records/checklist/${recordGroupId}/${history.get(
                        'date_id'
                      )}/`}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              );
            }

            return (
              <tr key={history.get('date_id')}>
                <td>{moment(history.get('date_id')).format('DD-MM-YYYY')}</td>
                <td>{history.get('answered_yes_count')}</td>
                <td>{history.get('answered_no_count')}</td>
                <td>{history.get('answered_na_count')}</td>
                <td>
                  <Link
                    className="base-font-color"
                    to={`/page/tools/records/checklist/${recordGroupId}/${history.get(
                      'date_id'
                    )}/`}
                  >
                    View
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {isLoadingMore && (
        <div className="mt-0 mb-2">
          <Loading />
        </div>
      )}

      {nextPage && !isLoadingMore && (
        <div className="text-center mb-2">
          <a
            className="inline-link"
            href="#"
            onClick={event => onLoadMoreHistoryClick(event, nextPage)}
          >
            Load more {'>>'}
          </a>
        </div>
      )}
    </div>
  );
};

export default ChecklistRecordGroupHistory;
