import React from 'react';
import { renderToString } from 'react-dom/server';
import { List } from 'immutable';
import moment from 'moment';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import LiveButton from '^/components/app/forms/LiveButton';
import PureComponent from '^/components/common/PureComponent';
import Icon from '^/components/app/content/Icon';
import Loading from '^/components/app/content/Loading';
import ChecklistRecordGroupHistory from '^/components/app/digital-tools/records/checklist/ChecklistRecordGroupHistory';
import AddSupportingDocumentsForm from '^/components/app/digital-tools/records/checklist/forms/AddSupportingDocumentsForm';
import StaticChecklistForm from '^/components/app/digital-tools/records/checklist/forms/StaticChecklistForm';
import DynamicChecklistForm from '^/components/app/digital-tools/records/checklist/forms/DynamicChecklistForm';

import {
  getDigitalRecordChecklist,
  getDigitalRecordChecklistHistory,
  createUpdateDigitalRecordChecklist,
} from '^/actions/actions';
import { getCollection } from '^/actions/collections';
import {
  openRefreshChecklistModal,
  openArchiveChecklistModal,
} from '^/actions/modals';
import {
  CHECKLIST_RECORD_GROUP_NAME_MAPPING,
  CHECKLIST_RECORD_DESCRIPTION_TEXT_MAPPING,
  CHECKLIST_RECORD_GROUP_FORM_NAME_MAPPING,
} from '^/components/app/digital-tools/records/constants';
import { isPending, hasFailed } from '^/consts/responseStates';
import { CHECKLIST_RECORD_GROUP } from '^/consts/collectionKeys';
import { createUpdateDigitalRecordChecklistAndAddRelatedFiles } from '^/actions/actionSequences';

export const ChecklistRecordGroupQRCode = ({
  recordTypeName,
  recordGroupName,
  imageBase64,
}) => (
  <div className="checklist-record-group-qr-code">
    <p>iComply</p>
    <p>Digital Records Checklist</p>
    <p>
      {recordTypeName}: {recordGroupName}
    </p>
    <img
      width="245"
      height="245"
      src={`data:image/jpeg;base64, ${imageBase64}`}
    />
  </div>
);

export class ChecklistRecordGroupsDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitDocuments = this.handleSubmitDocuments.bind(this);
    this.createSubmitHandler = this.createSubmitHandler(this);
    this.handleLoadMoreHistoryClick = this.handleLoadMoreHistoryClick.bind(
      this
    );
  }

  componentDidMount() {
    const { recordGroupId, date } = this.props;
    if (date) {
      this.props.getDigitalRecordChecklist(recordGroupId, date);
      this.props.getDigitalRecordChecklistHistory(recordGroupId);
    } else {
      // We date isn't specified in route params then load record groups so we
      // can redirect using current_date_id
      this.props.getCollection(CHECKLIST_RECORD_GROUP);
    }
  }

  componentDidUpdate(prevProps) {
    const { recordGroupId, date } = this.props;
    if (
      date &&
      (recordGroupId !== prevProps.recordGroupId || date !== prevProps.date)
    ) {
      this.props.getDigitalRecordChecklist(recordGroupId, date);
      this.props.getDigitalRecordChecklistHistory(recordGroupId);
    }
  }

  handleLoadMoreHistoryClick(event, nextPage) {
    event.preventDefault();
    if (nextPage) {
      const page = new window.URL(nextPage).searchParams.get('page');
      this.props.getDigitalRecordChecklistHistory(this.props.recordGroupId, {
        page,
        shouldAppend: true,
      });
    }
  }

  getQRCode(checklistRecordData) {
    const recordTypeName =
      CHECKLIST_RECORD_GROUP_NAME_MAPPING[
        checklistRecordData.get('record_type')
      ];
    const recordGroupName = checklistRecordData.get('name');
    const imageBase64 = checklistRecordData.get(
      'record_group_qr_code_base64_jpeg'
    );

    const htmlBody = renderToString(
      <ChecklistRecordGroupQRCode
        recordTypeName={recordTypeName}
        recordGroupName={recordGroupName}
        imageBase64={imageBase64}
      />
    );

    const w = window.open('');
    w.document.write(htmlBody);
  }

  handleChange(field, event, recordType, snapshotHash = null) {
    const { recordGroupId, date } = this.props;
    const data = {
      type: recordType,
      field_values: [
        {
          key: event.target.name,
          value: event.target.value,
          ...(snapshotHash === null ? {} : { snapshot_hash: snapshotHash }),
        },
      ],
    };

    this.props.createUpdateDigitalRecordChecklist(recordGroupId, date, data, {
      form: CHECKLIST_RECORD_GROUP_FORM_NAME_MAPPING[recordType],
      updatedFields: [event.target.name],
    });

    return field.onChange(event);
  }

  createSubmitHandler(recordType) {
    return formData => {
      const { recordGroupId, date, digitalRecordChecklist } = this.props;
      const data = {
        record_type: digitalRecordChecklist.get('type'),
        notes: formData.notes,
      };
      this.props.createUpdateDigitalRecordChecklist(recordGroupId, date, data, {
        form: recordType,
        updatedFields: ['notes'],
      });
    };
  }

  handleSubmitDocuments(data, recordType) {
    const { recordGroupId, date } = this.props;
    this.props.createUpdateDigitalRecordChecklistAndAddRelatedFiles(
      recordGroupId,
      date,
      {
        related_files: data.related_files.map(file => file.id || file.file_id),
        type: recordType,
        field_values: [],
      },
      {
        form: CHECKLIST_RECORD_GROUP_FORM_NAME_MAPPING[recordType],
        updatedFields: [],
      }
    );
  }

  render() {
    const {
      digitalRecordsChecklistRecordGroupsResponse,
      digitalRecordsChecklistRecordGroups,
      digitalRecordChecklistResponse,
      digitalRecordChecklist,
      digitalRecordChecklistHistory,
      digitalRecordChecklistHistoryResponse,
      date,
      recordGroupId,
      routePush,
    } = this.props;

    if (
      !date &&
      (isPending(digitalRecordsChecklistRecordGroupsResponse) ||
        !digitalRecordsChecklistRecordGroups)
    ) {
      return <Loading />;
    }

    // If date isn't specified in route params then we load record groups
    // collection and redirect to the latest checklist record using current_date_id
    if (!date) {
      const recordGroup = digitalRecordsChecklistRecordGroups.find(
        item => item.get('id') === recordGroupId
      );

      if (!recordGroup || !recordGroup.get('current_date_id')) {
        return <p>Oops! Could not retrieve this record group!</p>;
      }

      const currentDateId = recordGroup.get('current_date_id');
      routePush(
        `/page/tools/records/checklist/${recordGroupId}/${currentDateId}/`
      );
    }

    if (isPending(digitalRecordChecklistResponse) || !digitalRecordChecklist) {
      return <Loading />;
    }

    if (hasFailed(digitalRecordChecklistResponse)) {
      return <p>Oops! Could not retrieve this record group!</p>;
    }

    const dynamicChecklistSnapshotHeading = digitalRecordChecklist.get(
      'dynamic_checklist_snapshot_heading_field_map'
    );
    const recordType = digitalRecordChecklist.get('record_type');
    const submittedByName = digitalRecordChecklist.getIn([
      'submitted_by',
      'full_name',
    ]);

    const fieldsArray = digitalRecordChecklist
      .get('field_values')
      .map(field_value => field_value.get('key'))
      .toJS();
    fieldsArray.push('notes');

    const initialValues = {};
    digitalRecordChecklist
      .get('field_values')
      .toJS()
      .forEach(field =>
        Object.assign(initialValues, { [field.key]: field.value })
      );
    initialValues['notes'] = digitalRecordChecklist.get('notes');

    const RenderChecklistForm =
      dynamicChecklistSnapshotHeading === null
        ? StaticChecklistForm
        : DynamicChecklistForm;

    return (
      <div>
        <div className="checklist-meta mb-1">
          <div>
            <LiveButton
              response={digitalRecordChecklistResponse}
              onClick={() => this.getQRCode(digitalRecordChecklist)}
              className="btn btn-default indented pull-right ml-1"
            >
              <Icon type="qrcode" className="button-icon" />
              Get QR code
            </LiveButton>
            <LiveButton
              response={digitalRecordChecklistResponse}
              onClick={() =>
                this.props.openArchiveChecklistModal(recordGroupId)
              }
              className="btn-default btn-warning pull-right"
            >
              Archive
            </LiveButton>
          </div>
          <h1 className="mb-1">
            {CHECKLIST_RECORD_GROUP_NAME_MAPPING[recordType]}
          </h1>
          {submittedByName && (
            <p className="bold-text">Submitted By: {submittedByName}</p>
          )}
          {digitalRecordChecklist.get('meta_field_values') &&
            digitalRecordChecklist
              .get('meta_field_values')
              .toJS()
              .map(metaFieldValue => (
                <p key={metaFieldValue.key} className="bold-text">
                  {metaFieldValue.name}: {metaFieldValue.value}
                </p>
              ))}
        </div>
        <div className="display-flex">
          <p className="bold-text">Date: {moment(date).format('DD-MM-YYYY')}</p>
          <p className="bold-text">
            Location / Room: {digitalRecordChecklist.get('name')}
          </p>
        </div>
        <div className="checklist-border mb-1 mt-1" />
        {CHECKLIST_RECORD_DESCRIPTION_TEXT_MAPPING[recordType].map(
          (paragraph, index) => (
            <p key={index}>{paragraph}</p>
          )
        )}
        <div className="checklist-border mb-1 mt-1" />
        {dynamicChecklistSnapshotHeading && (
          <div className="form-group">
            <LiveButton
              response={digitalRecordChecklistResponse}
              onClick={() =>
                this.props.openRefreshChecklistModal(recordGroupId, date)
              }
              className="btn-default pull-left"
            >
              Refresh Data
            </LiveButton>
          </div>
        )}

        <RenderChecklistForm
          createUpdateDigitalRecordChecklist={
            this.props.createUpdateDigitalRecordChecklist
          }
          digitalRecordChecklistType={recordType}
          recordGroupId={recordGroupId}
          date={date}
          initialValues={initialValues}
          onSubmit={this.createSubmitHandler}
          handleChange={this.handleChange}
          form={CHECKLIST_RECORD_GROUP_FORM_NAME_MAPPING[recordType]}
          fields={fieldsArray}
          dynamicChecklistSnapshotHeading={dynamicChecklistSnapshotHeading}
          fieldsData={digitalRecordChecklist.get('field_values').toJS()}
        />
        <AddSupportingDocumentsForm
          buttonText="Save / update documents on checklist"
          initialValues={{
            related_files: digitalRecordChecklist
              .get('related_files', List())
              .toJS(),
          }}
          onSubmit={data => this.handleSubmitDocuments(data, recordType)}
          responseName="updateChecklistRelatedFiles"
          successText="Documents successfully updated on checklist!"
        />
        <ChecklistRecordGroupHistory
          checklistHistory={digitalRecordChecklistHistory}
          checklistHistoryResponse={digitalRecordChecklistHistoryResponse}
          recordGroupId={recordGroupId}
          onLoadMoreHistoryClick={this.handleLoadMoreHistoryClick}
        />
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    digitalRecordsChecklistRecordGroups: state.collections.getIn([
      CHECKLIST_RECORD_GROUP,
      'items',
    ]),
    digitalRecordsChecklistRecordGroupsResponse: state.responses.getIn([
      'getCollection',
      CHECKLIST_RECORD_GROUP,
    ]),
    digitalRecordChecklist: state.digitalRecordChecklist,
    digitalRecordChecklistHistory: state.digitalRecordChecklistHistory,
    digitalRecordChecklistResponse: state.responses.get(
      'getDigitalRecordChecklist'
    ),
    digitalRecordChecklistHistoryResponse: state.responses.get(
      'getDigitalRecordChecklistHistory'
    ),
    recordGroupId: props.params?.recordGroupId,
    date: props.params?.date,
  };
}

export default connect(mapStateToProps, {
  getCollection,
  getDigitalRecordChecklist,
  getDigitalRecordChecklistHistory,
  createUpdateDigitalRecordChecklist,
  createUpdateDigitalRecordChecklistAndAddRelatedFiles,
  openRefreshChecklistModal,
  openArchiveChecklistModal,
  routePush: routeActions.push,
})(ChecklistRecordGroupsDetail);
