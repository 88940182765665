import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import { getAuditReviewScores, getAuditReviewTasks } from '^/actions/audit';
import {
  AuditReviewScores,
  Task,
} from '^/components/app/digital-tools/audit/types';
import { OverallScoreDisplay } from '^/components/app/healthcheck/review';
import { STATUS_NAMES } from '^/models/activities';
import { selectAuditScoresById, selectAuditTasksById } from '^/selectors/audit';
import { StoreState } from '^/store/types';
import { asPercent } from '^/utils';
import ReviewPageWrapper from './review-page-wrapper';
import Summary from './summary';

type RouteProps = RouteComponentProps<{ auditId: string }, {}>;

interface StateProps {
  reviewScores: AuditReviewScores | null;
  reviewTasks: readonly Task[] | null;
  isLoading: boolean;
}

interface DispatchProps {
  getAuditReviewScores: typeof getAuditReviewScores;
  getAuditReviewTasks: typeof getAuditReviewTasks;
}

type Props = RouteProps & StateProps & DispatchProps;

class AuditReview extends Component<Props, {}> {
  public componentDidMount() {
    if (!this.props.reviewScores) {
      this.props.getAuditReviewScores(this.props.params.auditId);
    }
    if (!this.props.reviewTasks) {
      this.props.getAuditReviewTasks(this.props.params.auditId);
    }
  }

  public render() {
    return (
      <ReviewPageWrapper
        auditId={this.props.params.auditId}
        loadingContent={this.props.isLoading}
        loadingContentFailed={
          !this.props.reviewScores || !this.props.reviewTasks
        }
        renderContent={this.renderContent}
        isResultsScreen
      />
    );
  }

  private renderContent = () => {
    const { reviewScores, reviewTasks } = this.props;

    if (!reviewScores || !reviewTasks) {
      return null;
    }

    return (
      <div>
        <div>
          <OverallScoreDisplay overallScore={reviewScores.overall_score} />
          <div className="audit-review-table-wrapper">
            <table className="audit-review-table audit-review-table-scores">
              <tbody>
                <tr>
                  <th>Category</th>
                  <th>Score</th>
                  <th>Follow-up actions scheduled</th>
                </tr>
                {reviewScores.categories.map(category => (
                  <tr key={category.id}>
                    <td>{category.name}</td>
                    <td>{asPercent(category.score)}%</td>
                    <td>{category.task_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-3">
          <h2>Follow-up actions</h2>
          <div className="audit-review-table-wrapper">
            {reviewTasks.length ? (
              <table className="audit-review-table audit-review-table-tasks">
                <tbody>
                  <tr>
                    <th>Title</th>
                    <th>Due date</th>
                    <th>Assigned to</th>
                    <th>Status</th>
                    <th>Notes</th>
                  </tr>
                  {reviewTasks.map(task => (
                    <tr key={task.id}>
                      <td>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/page/tasks/manage/${task.id}/`}
                        >
                          {task.title}
                        </a>
                      </td>
                      <td>
                        {task.deadline
                          ? moment(task.deadline, 'YYYY-MM-DD').format(
                              'DD-MM-YYYY'
                            )
                          : '-'}
                      </td>
                      <td>
                        {task.assignees.length === 1
                          ? task.assignees[0].full_name
                          : task.assignees
                              .map(assignee => assignee.full_name)
                              .join(', ')}
                      </td>
                      <td>{STATUS_NAMES[task.status]}</td>
                      <td>
                        <p className="pre-wrap audit-review-table-note">
                          {task.entry}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="mb-1 mt-1">No follow up actions created</div>
            )}
          </div>
        </div>
        <Summary auditId={this.props.params.auditId} />
      </div>
    );
  };
}

export { AuditReview as AuditReviewUnconnected };

const mapStateToProps = (state: StoreState, props: RouteProps): StateProps => ({
  reviewScores: selectAuditScoresById(state, props.params.auditId),
  reviewTasks: props ? selectAuditTasksById(state, props.params.auditId) : null,
  isLoading:
    state.thunkRequests.getAuditReviewScores.isLoading ||
    state.thunkRequests.getAuditReviewTasks.isLoading,
});

export default connect<StateProps, DispatchProps, RouteProps>(mapStateToProps, {
  getAuditReviewScores,
  getAuditReviewTasks,
})(AuditReview);
