import { Map } from 'immutable';
import makeRsaAction, { makeRawRsaAction } from '^/middleware/makeRsaAction';
import moment from 'moment';
import { formatBackendDate } from '^/utils';
import { makeAsyncActionSet } from '^/actions/utils';
import { STATUS_CHOICES } from '^/models/staffPractice';
import { ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS, NEWS } from '^/consts/collectionKeys';

export const LOGIN = makeAsyncActionSet('LOGIN');

export function login(accessToken, idToken) {
  return makeRsaAction(
    LOGIN,
    '/users/login/',
    'POST',
    { access_token:  accessToken, id_token: idToken}
  );
}

export const SAVE_USERNAME = 'SAVE_USERNAME';

export function saveUsername(email) {
  return { type: SAVE_USERNAME, payload: {email} };
}

export const SET_PENDING_FIELDS = 'SET_PENDING_FIELDS';

export function setPendingFields(collection, data) {
  return { type: SET_PENDING_FIELDS, payload: {collection, data} };
}

export const CLEAR_USERNAME = 'CLEAR_USERNAME';

export function clearUsername() {
  return { type: CLEAR_USERNAME };
}

export const LOAD_USERNAME = 'LOAD_USERNAME';

export function loadUsername(username) {
  return { type: LOAD_USERNAME, payload: {username} };
}

export const SET_SELECT_VALUE = 'SET_SELECT_VALUE';

export function setSelectValue(controlName, value) {
  return { type: SET_SELECT_VALUE, payload: { controlName, value } };
}

export const CLEAR_SELECT = 'CLEAR_SELECT';

export function clearSelect(controlName) {
  return { type: CLEAR_SELECT, payload: { controlName } };
}

export const REGISTER = makeAsyncActionSet('REGISTER');

export function register(data) {
  return makeRsaAction(
    REGISTER,
    '/users/register/',
    'POST',
    data
  );
}

export const REMEMBER_LOCATION = 'REMEMBER_LOCATION';

export function rememberLocation(location) {
  return {
    type: REMEMBER_LOCATION,
    payload: {
      path: location.pathname,
      search: location.search,
      hash: location.hash,
    }
  };
}

export const GET_USER_INFO = makeAsyncActionSet('GET_USER_INFO');

export function getUserInfo() {
  return makeRsaAction(
    GET_USER_INFO,
    '/users/me/',
    'GET'
  );
}

export const RESET_DEMO = makeAsyncActionSet('RESET_DEMO');

export function resetDemo() {
  return makeRsaAction(
    RESET_DEMO,
    '/users/reset-demo/',
    'POST'
  );
}

export const CHANGE_PASSWORD = makeAsyncActionSet('CHANGE_PASSWORD');

export function changePassword(data) {
  return makeRsaAction(
    CHANGE_PASSWORD,
    '/users/password/change/',
    'POST',
    data
  );
}

export const SET_USER_LOCK = makeAsyncActionSet('SET_USER_LOCK');

export function setUserLock(userId, locked) {
  return makeRsaAction(
    SET_USER_LOCK,
    `/admin/users/${userId}/lock/`,
    'POST',
    { is_locked: locked }
  );
}

export const RESET_RESPONSE = 'RESET_RESPONSE';

export function resetResponse(key) {
  return { type: RESET_RESPONSE, payload: key };
}

export const RESET_RESPONSE_ERROR_FOR_KEY = 'RESET_RESPONSE_ERROR_FOR_KEY';
export function resetResponseErrorForKey(responseKey, key) {
  return { type: RESET_RESPONSE_ERROR_FOR_KEY, payload: {responseKey, key} };
}


export const SET_RESPONSE_ERROR_FOR_KEY = 'SET_RESPONSE_ERROR_FOR_KEY';
export function setResponseErrorForKey(responseKey, key, payload) {
  return {
    type: SET_RESPONSE_ERROR_FOR_KEY,
    responseKey,
    key,
    payload
  };
}

export const RESEND_INVITE = makeAsyncActionSet('RESEND_INVITE');
export function resendInvite(invitedUserId) {
  return makeRsaAction(
    RESEND_INVITE,
    `/invites/${invitedUserId}/resend/`,
    'POST',
    {},
    {invitedUserId}
  );
}

export const REMOVE_INVITE = makeAsyncActionSet('REMOVE_INVITE');
export function removeInvite(inviteId) {
  return makeRsaAction(
    REMOVE_INVITE,
    `/invites/${inviteId}/remove/`,
    'POST',
    {},
    {
      source: inviteId
    }
  );
}

export const REMOVE_SSO_INVITE = makeAsyncActionSet('REMOVE_SSO_INVITE');
export function removeSSOInvite(inviteId) {
  return makeRsaAction(
    REMOVE_SSO_INVITE,
    `/invites/${inviteId}/remove-sso-invite/`,
    'POST',
    {},
    {
      source: inviteId
    }
  );
}

export const SWITCH_PRACTICE = makeAsyncActionSet('SWITCH_PRACTICE');
export function switchPractice(practiceId) {
  return makeRsaAction(
    SWITCH_PRACTICE,
    '/users/switch-practice/',
    'PUT',
    {
      current_practice: practiceId,
    }
  );
}

export const TOGGLE_SIDEBAR_MENU = 'TOGGLE_SIDEBAR_MENU';
export const TOGGLE_LOGGED_IN_AS_DROPDOWN = 'TOGGLE_LOGGED_IN_AS_DROPDOWN';
export const OPEN_SIDEBAR_MENU = 'OPEN_SIDEBAR_MENU';
export const CLOSE_SIDEBAR_MENU = 'CLOSE_SIDEBAR_MENU';

export function toggleLoggedInAsDropdown() {
  return {
    type: TOGGLE_LOGGED_IN_AS_DROPDOWN,
  };
}

export function toggleSidebarMenu(title) {
  return {
    type: TOGGLE_SIDEBAR_MENU,
    payload: title
  };
}

export function openSidebarMenu(title) {
  return {
    type: OPEN_SIDEBAR_MENU,
    payload: title
  };
}

export function closeSidebarMenu(title) {
  return {
    type: CLOSE_SIDEBAR_MENU,
    payload: title
  };
}

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export function toggleSidebar() {
  return { type: TOGGLE_SIDEBAR };
}

export const STORE_IMAGE_STYLE = 'STORE_IMAGE_STYLE';
export function storeImageStyle(ident, style) {
  return {
    type: STORE_IMAGE_STYLE,
    payload: Map({
      ident,
      style
    })
  };
}

export const SET_PENDING_UPLOAD_IN_FORM = 'SET_PENDING_UPLOAD_IN_FORM';
export function setPendingUploadInForm(form, field, file) {
  return { type: SET_PENDING_UPLOAD_IN_FORM, payload: { form, field, file } };
}

export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export function toggleSearch() {
  return { type: TOGGLE_SEARCH };
}

export const EXPAND_FILTER = 'EXPAND_FILTER';
export function expandFilter(collection, filterKey) {
  return { type: EXPAND_FILTER, collection, filterKey };
}

export const CLOSE_FILTERS = 'CLOSE_FILTERS';
export function closeFilters(collection) {
  return { type: CLOSE_FILTERS, collection };
}

export const GET_FILTER_OPTIONS = makeAsyncActionSet('GET_FILTER_OPTIONS');
export function getFilterOptions(collection, filterKey, optionsEndpoint, requestType = 'GET', practice = null) {
  const orgFilter = practice ? { practice } : {};
  return makeRsaAction(
    GET_FILTER_OPTIONS,
    optionsEndpoint,
    requestType,
    orgFilter,
    {
      collection,
      filterKey
    }
  );
}

export const SET_FILTER = 'SET_FILTER';
export function setFilter(collection, filterKey, filterValue) {
  return {type: SET_FILTER, collection, filterKey, filterValue};
}

export const CLEAR_FILTER = 'CLEAR_FILTER';
export function clearFilter(collection, filterKey) {
  return {type: CLEAR_FILTER, collection, filterKey};
}

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export function clearFilters(collection) {
  return {type: CLEAR_FILTERS, collection};
}

export const SET_FILTER_VALUE = 'SET_FILTER_VALUE';
export function setFilterValue(collection, filterKey, filterValue) {
  return {type: SET_FILTER_VALUE, collection, filterKey, filterValue};
}

export const SET_PAGE_HEADER = 'SET_PAGE_HEADER';
export function setPageHeader(payload) {
  return {type: SET_PAGE_HEADER, payload};
}

export const SET_PARENT_SIZE = 'SET_PARENT_SIZE';
export function setParentSize(size) {
  return {type: SET_PARENT_SIZE, payload: size};
}

export const CLEAR_PAGE_HEADER = 'CLEAR_PAGE_HEADER';
export function clearPageHeader() {
  return {type: CLEAR_PAGE_HEADER};
}

export const SET_COLLAPSE = 'SET_COLLAPSE';
export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE';

export function setCollapse(collapseId, collapsed) {
  return {
    type: SET_COLLAPSE,
    payload: {
      collapseId,
      collapsed,
    }
  };
}

export const CLEAR_CUSTOM_FIELDS = 'CLEAR_CUSTOM_FIELDS';
export function clearCustomFields() {
  return { type: CLEAR_CUSTOM_FIELDS };
};

export const ADD_CUSTOM_FIELD = 'ADD_CUSTOM_FIELD';
export function addCustomField(customField) {
  return {
    type: ADD_CUSTOM_FIELD,
    payload: customField,
  };
};

export const REMOVE_CUSTOM_FIELD = 'REMOVE_CUSTOM_FIELD';
export function removeCustomField(customField) {
  return {
    type: REMOVE_CUSTOM_FIELD,
    payload: customField,
  };
};

export function toggleCollapse(collapseId) {
  return {
    type: TOGGLE_COLLAPSE,
    payload: {
      collapseId,
    },
  };
}

export const REMOVE_PARENT_SIZE = 'REMOVE_PARENT_SIZE';
export function removeParentSize(id) {
  return {type: REMOVE_PARENT_SIZE, id};
}

export const SET_SORT_BY = 'SET_SORT_BY';
export function setSortBy(listName, value) {
  return {type: SET_SORT_BY, listName, value};
}

export const SET_TABLE_PAGE_SIZE = 'SET_TABLE_PAGE_SIZE';
export function setTablePageSize(listName, value) {
  return {type: SET_TABLE_PAGE_SIZE, listName, value};
}

export const SET_SORT_BY_REVERSED = 'SET_SORT_BY_REVERSED';
export function setSortByReversed(listName, value) {
  return {type: SET_SORT_BY_REVERSED, listName, value};
}

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export function setActiveTab(tabsetName, value) {
  return {type: SET_ACTIVE_TAB, payload: {tabsetName, value}};
}

export const CLEAR_TABSET = 'CLEAR_TABSET';
export function clearTabset(tabset) {
  return {type: CLEAR_TABSET, payload: { tabset }};
}

export const SET_CURRENT_MONTH = 'SET_CURRENT_MONTH';
export function setCurrentMonth(currentMonth) {
  return {type: SET_CURRENT_MONTH, payload: currentMonth};
}

export const SET_INITIAL_MONTH = 'SET_INITIAL_MONTH';
export function setInitialMonth(initalMonth) {
  return { type: SET_INITIAL_MONTH, payload: initalMonth };
}

export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export function setSelectedEvent(type, object, x, y) {
  return {type: SET_SELECTED_EVENT, payload: { type, object, x, y}};
}

export const UPDATE_CHART_WIDTH = 'UPDATE_CHART_WIDTH';
export function updateChartWidth(chartId, width) {
  return {type: UPDATE_CHART_WIDTH, payload: {chartId, width}};
}

export const GET_EXPORT_TABLES = makeAsyncActionSet('GET_EXPORT_TABLES');
export function getExportTables() {
  return makeRsaAction(
    GET_EXPORT_TABLES,
    '/export/table/',
    'OPTIONS'
  );
}

export const GET_EXPORT_URL = makeAsyncActionSet('GET_EXPORT_URL');
export function getExportUrl(table) {
  return makeRsaAction(
    GET_EXPORT_URL,
    '/export/table/',
    'POST',
    { table }
  );
}

export const SET_CURRENT_TEAM_MEMBER = 'SET_CURRENT_TEAM_MEMBER';
export function setCurrentTeamMember(currentTeamMember) {
  return {type: SET_CURRENT_TEAM_MEMBER, payload: currentTeamMember};
}

export const SET_HIGHLIGHTED_INDEX = 'SET_HIGHLIGHTED_INDEX';

export function setHighlightedIndex(id, index) {
  return { type: SET_HIGHLIGHTED_INDEX, payload: { id, index } };
}

export const CLEAR_HIGHLIGHTED_INDEX = 'CLEAR_HIGHLIGHTED_INDEX';

export function clearHighlightedIndex(id) {
  return { type: CLEAR_HIGHLIGHTED_INDEX, payload: { id } };
}

export const EXPAND_UI_COMPONENT = 'EXPAND_UI_COMPONENT';
export const COLLAPSE_UI_COMPONENT = 'COLLAPSE_UI_COMPONENT';

export const COLLAPSE_ALL_UI_COMPONENTS = 'COLLAPSE_ALL_UI_COMPONENTS';
export const EXPAND_ALL_UI_COMPONENTS = 'EXPAND_ALL_UI_COMPONENTS';

export function expandUiComponent(id, groupId) {
  return { type: EXPAND_UI_COMPONENT, payload: { id, groupId } };
}

export function collapseUiComponent(id, groupId) {
  return { type: COLLAPSE_UI_COMPONENT, payload: { id, groupId } };
}

export function collapseAllUiComponents() {
  return { type: COLLAPSE_ALL_UI_COMPONENTS };
}

export function expandAllUiComponents(componentIds) {
  return { type: EXPAND_ALL_UI_COMPONENTS, payload: { componentIds } };
}

export const UPDATE_SEARCH_ITEM_QUERY = 'UPDATE_SEARCH_ITEM_QUERY';

export function updateSearchItemQuery(id, searchQuery) {
  return { type: UPDATE_SEARCH_ITEM_QUERY, payload: { id, searchQuery } };
}

export const ADD_REASSIGN_DATA = 'ADD_REASSIGN_DATA';

export function addReassignData(form, data) {
  return { type: ADD_REASSIGN_DATA, payload: { form, data } };
}

export const CLEAR_REASSIGN_DATA = 'CLEAR_REASSIGN_DATA';

export function clearReassignData() {
  return { type: CLEAR_REASSIGN_DATA };
}


export const SET_CAPTCHA = 'SET_CAPTCHA';

export function setCaptcha(captcha) {
  return { type: SET_CAPTCHA, payload: captcha };
}

export const ENABLE_NAV = 'ENABLE_NAV';

export function enableNav() {
  return { type: ENABLE_NAV, payload: true };
}

export const DISABLE_NAV = 'DISABLE_NAV';

export function disableNav() {
  return { type: DISABLE_NAV, payload: false };
}

export const SETUP_PERSONAL_DETAILS = makeAsyncActionSet('SETUP_PERSONAL_DETAILS');
export function setupPersonalDetails(userId, data) {
  return makeRsaAction(
    SETUP_PERSONAL_DETAILS,
    `/users/${userId}/setup/personal-details/`,
    'PUT',
    data
  );
}


export const SETUP_PRACTICE_DETAILS = makeAsyncActionSet('SETUP_PRACTICE_DETAILS');
export function setupPracticeDetails(data) {
  return makeRsaAction(
    SETUP_PRACTICE_DETAILS,
    '/practices/setup/practice-details/',
    'POST',
    data
  );
}

export const SETUP_TEAM_MEMBERS = makeAsyncActionSet('SETUP_TEAM_MEMBERS');
export function setupTeamMembers(practiceId, data) {
  return makeRsaAction(
    SETUP_TEAM_MEMBERS,
    `/practices/${practiceId}/setup/team-members/`,
    'PUT',
    data
  );
}

export const LOGOUT = makeAsyncActionSet('LOGOUT');
export function logout() {
  return makeRsaAction(
    LOGOUT,
    '/users/logout/',
    'POST'
  );
}

export const CLEAR_USER = 'CLEAR_USER';
export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}

export const GET_SETUP_TEAM_MEMBERS = makeAsyncActionSet('GET_SETUP_TEAM_MEMBERS');
export function getSetupTeamMembers(practiceId) {
  return makeRsaAction(
    GET_SETUP_TEAM_MEMBERS,
    `/practices/${practiceId}/setup/team-members/`,
    'GET'
  );
}

export const GET_SUB_GROUPS_FOR_CURRENT_GROUP = makeAsyncActionSet('GET_SUB_GROUPS_FOR_CURRENT_GROUP');
export function getSubGroupsForCurrentGroup() {
  return makeRsaAction(
    GET_SUB_GROUPS_FOR_CURRENT_GROUP,
    '/practices/sub-groups-for-current-group/',
    'GET'
  );
}

export const GET_TEMPLATE_ACTIVITIES_FOR_CURRENT_GROUP = makeAsyncActionSet('GET_TEMPLATE_ACTIVITIES_FOR_CURRENT_GROUP');
export function getTemplateActivitiesForCurrentGroup(filters = {}) {
  return makeRsaAction(
    GET_TEMPLATE_ACTIVITIES_FOR_CURRENT_GROUP,
    '/group-insights/template-activities-for-current-group/',
    'GET',
    { ...filters }
  );
}

export const SETUP_CYCLE_RESPONSIBILITIES = makeAsyncActionSet('SETUP_CYCLE_RESPONSIBILITIES');
export function setupCycleResponsibilities(practiceId, data) {
  return makeRsaAction(
    SETUP_CYCLE_RESPONSIBILITIES,
    `/cycles/setup/cycle-responsibilities/${practiceId}/`,
    'POST',
    data
  );
}


export const SETUP_CYCLE_REMINDERS = makeAsyncActionSet('SETUP_CYCLE_REMINDERS');
export function setupCycleReminders(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_REMINDERS,
    `/cycles/${cycleId}/setup/reminders/`,
    'PUT',
    data
  );
}

export const SETUP_CYCLE_GROUP_TASKS = makeAsyncActionSet('SETUP_CYCLE_GROUP_TASKS');
export function setupCycleGroupTasks(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_GROUP_TASKS,
    `/cycles/${cycleId}/setup/group-tasks/`,
    'PUT',
    { group_tasks: data },
  );
}

export const SET_SHOWING = 'SET_SHOWING';
export function setShowing(showingSet, showing) {
  return { type: SET_SHOWING, payload: { showingSet, showing } };
}

export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export function setFormError(formId, errors) {
  return { type: SET_FORM_ERROR, payload: {formId, errors} };
}

export const SETUP_COMPLIANCE_REVIEWS = makeAsyncActionSet('SETUP_COMPLIANCE_REVIEWS');
export function setupComplianceReviews(cycleId, data) {
  return makeRsaAction(
    SETUP_COMPLIANCE_REVIEWS,
    `/cycles/${cycleId}/setup/compliance-reviews/`,
    'POST',
    data
  );
}

export const SETUP_SECURITY_AUDITS = makeAsyncActionSet('SETUP_SECURITY_AUDITS');
export function setupSecurityAudits(cycleId, data) {
  return makeRsaAction(
    SETUP_SECURITY_AUDITS,
    `/cycles/${cycleId}/setup/security-audits/`,
    'POST',
    data
  );
}

export const CHANGE_TEAM_PRACTICE_SELECT = 'CHANGE_TEAM_PRACTICE_SELECT';
export function changeTeamPracticeSelect(practiceId) {
  return {type: CHANGE_TEAM_PRACTICE_SELECT, payload: practiceId };
}

export const LOAD_TEMPLATE_ACTIVITIES = makeAsyncActionSet('LOAD_TEMPLATE_ACTIVITIES');
export function loadTemplateActivities(activityTemplateType, practiceId, cycleId) {
  return makeRsaAction(
    LOAD_TEMPLATE_ACTIVITIES,
    `/${activityTemplateType}/${practiceId}/${cycleId}/`,
    'GET',
    {},
    {activityTemplateType}
  );
}


export const SETUP_ASSESSMENTS = makeAsyncActionSet('SETUP_ASSESSMENTS');
export function setupAssessments(cycleId, data) {
  return makeRsaAction(
    SETUP_ASSESSMENTS,
    `/cycles/${cycleId}/setup/assessments/`,
    'POST',
    data
  );
}

export const SETUP_MEETINGS = makeAsyncActionSet('SETUP_MEETINGS');
export function setupMeetings(cycleId, data) {
  return makeRsaAction(
    SETUP_MEETINGS,
    `/cycles/${cycleId}/setup/meetings/`,
    'POST',
    data
  );
}

export const SET_SEARCH_TEXT = makeAsyncActionSet('SET_SEARCH_TEXT');
export function setSearchText(searchText) {
  return { type: SET_SEARCH_TEXT, payload: searchText };
}

export const SET_DEFAULT_DOCUMENT_SEARCH_VALUE = makeAsyncActionSet('SET_DEFAULT_DOCUMENT_SEARCH_VALUE');
export function setDefaultDocumentSearchValue(value) {
  return {type: SET_DEFAULT_DOCUMENT_SEARCH_VALUE, payload: value};
}

export const SET_EDITOR_CONTENT = 'SET_EDITOR_CONTENT';
export function setEditorContent(editorContent) {
  return { type: SET_EDITOR_CONTENT, payload: editorContent };
}

export const INITIATE_TRANSACTION = makeAsyncActionSet('INITIATE_TRANSACTION');
export function initiateTransaction(data) {
  return makeRsaAction(
    INITIATE_TRANSACTION,
    '/payments/initiate-transaction/',
    'POST',
    data
  );
}

export const RENDER_DOCUMENT = makeAsyncActionSet('RENDER_DOCUMENT');
export function renderDocument(documentType, documentId) {
  return makeRsaAction(
    RENDER_DOCUMENT,
    `/documents/render/${documentType}/${documentId}/`,
    'POST',
    null,
    { source: documentId }
  );
}

export const RENDER_DOCUMENT_VERSION_TO_READ = makeAsyncActionSet('RENDER_DOCUMENT_VERSION_TO_READ');
export function renderDocumentVersionToRead(documentId, documentVersionToReadId) {
  return makeRsaAction(
    RENDER_DOCUMENT_VERSION_TO_READ,
    `/documents/render/${documentId}/document-version-to-read/${documentVersionToReadId}/`,
    'POST',
    null,
    { source: documentId }
  );
}

export const RENDER_ADMIN_DOCUMENT = makeAsyncActionSet('RENDER_ADMIN_DOCUMENT');
export function renderAdminDocumentVersion(documentType, documentVersionId) {
  return makeRsaAction(
    RENDER_ADMIN_DOCUMENT,
    `/admin/document/${documentType}/${documentVersionId}/render/`,
    'POST',
    null,
    { source: documentVersionId }
  );
}

export const RENDER_QMR_REPORT = makeAsyncActionSet('RENDER_QMR_REPORT');
export function renderQMRReport(reportId) {
  return makeRsaAction(
    RENDER_QMR_REPORT,
    `/reports/render-qmr-report/${reportId}/`,
    'POST',
    null,
    { source: reportId }
  );
}

export const POLL_EXPORT = makeAsyncActionSet('POLL_EXPORT');
export function pollExport(exportId) {
  return makeRsaAction(
    POLL_EXPORT,
    `/export/status/${exportId}/`,
    'GET',
    null,
    { source: exportId }
  );
}

export const STORE_POLL_TIMEOUT = 'STORE_POLL_TIMEOUT';
export function storePollTimeout(exportId, timeout = null) {
  return {
    type: STORE_POLL_TIMEOUT,
    payload: { exportId, timeout, },
  };
}

export const RETRY_EXPORT = makeAsyncActionSet('RETRY_EXPORT');
export function retryExport(exportId) {
  return makeRsaAction(
    RETRY_EXPORT,
    `/export/retry/${exportId}/`,
    'POST',
    null,
    {source: exportId}
  );
}

export const SETUP_NEW_CYCLE = makeAsyncActionSet('SETUP_NEW_CYCLE');
export function setupNewCycle(practiceId, startDate) {
  return makeRsaAction(
    SETUP_NEW_CYCLE,
    '/cycles/',
    'POST',
    {
      practice: practiceId,
      start_date: formatBackendDate(startDate),
      end_date: formatBackendDate(moment(startDate).add(11, 'months').endOf('month')),
    }
  );
}

export const SETUP_CYCLE_COMPLIANCE_DETAILS = makeAsyncActionSet('SETUP_CYCLE_COMPLIANCE_DETAILS');
export function setupCycleComplianceDetails(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_COMPLIANCE_DETAILS,
    `/cycles/${cycleId}/setup/compliance-details/`,
    'POST',
    data
  );
}

const getPathFor = activityTemplateType => {
  switch (activityTemplateType) {
    case 'activities/setup/security-audits':
      return 'previous-audits';
    case 'activities/setup/meetings':
      return 'previous-meetings';
    case 'activities/setup/assessments':
      return 'previous-assessments';
    default:
      return undefined;
  }
};

export const LOAD_TEMPLATE_ACTIVITY_TYPE_LAST_DONE = makeAsyncActionSet('LOAD_TEMPLATE_ACTIVITY_TYPE_LAST_DONE');
export function loadTemplateActivityTypeLastDone(activityTemplateType, cycleId) {
  return makeRsaAction(
    LOAD_TEMPLATE_ACTIVITY_TYPE_LAST_DONE,
    `/cycles/${cycleId}/${getPathFor(activityTemplateType)}/`,
    'GET',
    {},
    {activityTemplateType}
  );
}

export const SETUP_CYCLE_OPTIONAL_AUDITS = makeAsyncActionSet('SETUP_CYCLE_OPTIONAL_AUDITS');
export function setupCycleOptionalAudits(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_OPTIONAL_AUDITS,
    `/cycles/${cycleId}/setup/optional-audits/`,
    'POST',
    data
  );
}

export const SETUP_CYCLE_REVIEW_RISK_ASSESSMENTS = makeAsyncActionSet('SETUP_CYCLE_REVIEW_RISK_ASSESSMENTS');
export function setupCycleReviewRiskAssessments(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_REVIEW_RISK_ASSESSMENTS,
    `/cycles/${cycleId}/setup/review-assessments/`,
    'POST',
    data
  );
}

export const SETUP_CYCLE_REVIEW_MEETINGS = makeAsyncActionSet('SETUP_CYCLE_REVIEW_MEETINGS');
export function setupCycleReviewMeetings(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_REVIEW_MEETINGS,
    `/cycles/${cycleId}/setup/review-meetings/`,
    'POST',
    data
  );
}

export const SETUP_CYCLE_REVIEW_TASKS = makeAsyncActionSet('SETUP_CYCLE_REVIEW_TASKS');
export function setupCycleReviewTasks(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_REVIEW_TASKS,
    `/cycles/${cycleId}/setup/review-tasks/`,
    'PUT',
    data
  );
}

export const SETUP_CYCLE_REVIEW_GROUP_TASKS = makeAsyncActionSet('SETUP_CYCLE_REVIEW_GROUP_TASKS');
export function setupCycleReviewGroupTasks(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_REVIEW_GROUP_TASKS,
    `/cycles/${cycleId}/setup/review-group-tasks/`,
    'PUT',
    { group_tasks: data }
  );
}

export const REASSIGN_MEMBER = makeAsyncActionSet('REASSIGN_MEMBER');
export function _reassignMember(staffPracticeId, data) {
  return makeRsaAction(
    REASSIGN_MEMBER,
    `/practices/reassign/${staffPracticeId}/`,
    'POST',
    data
  );
}

export function reassignAndSuspendMember(staffPracticeId, data) {
  return _reassignMember(
    staffPracticeId,
    Object.assign({}, data, {new_status: STATUS_CHOICES.SUSPENDED})
  );
}

export function reassignAndRetireMember(staffPracticeId, data) {
  return _reassignMember(
    staffPracticeId,
    Object.assign({}, data, {new_status: STATUS_CHOICES.RETIRED})
  );
}

export function reinstateMember(staffPracticeId) {
  return _reassignMember(
    staffPracticeId,
    {new_status: STATUS_CHOICES.ACTIVE}
  );
}

export const CREATE_CYCLE_SETUP_TASK = 'CREATE_CYCLE_SETUP_TASK';
export function createCycleSetupTask(cycleId, data) {
  return { type: CREATE_CYCLE_SETUP_TASK, payload: { cycleId, data, }, };
}

export const SETUP_CYCLE_COMPLIANCE_REVIEWS = makeAsyncActionSet('SETUP_CYCLE_COMPLIANCE_REVIEWS');
export function setupCycleComplianceReviews(cycleId, data) {
  return makeRsaAction(
    SETUP_CYCLE_COMPLIANCE_REVIEWS,
    `/cycles/${cycleId}/setup/additional-compliance-reviews/`,
    'POST',
    data
  );
}

export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';
export function setDiscountCode(discountCode) {
  return { type: SET_DISCOUNT_CODE, payload: discountCode };
}

export const GET_LATEST_TERMS = makeAsyncActionSet('GET_LATEST_TERMS');

export function getLatestTerms() {
  return makeRsaAction(
    GET_LATEST_TERMS,
    '/users/latest-terms/',
    'GET'
  );
}

export const UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE = makeAsyncActionSet('UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE');
export function uploadActivityTaskAssigneeIdFile(activityTaskAssigneeId, data) {
  // strip null and undefined as they are not valid values for this
  const dataToSubmit = Map(data)
    .filterNot(value => value === null || value === undefined)
    .toJS();

  return makeRsaAction(
    UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE,
    `/documents/uploaded-files/${activityTaskAssigneeId}/`,
    'POST',
    dataToSubmit
  );
}


export const REMOVE_UPLOADED_FILE = makeAsyncActionSet('REMOVE_UPLOADED_FILE');
export function removeUploadedFile(activityOrTaskAssigneeId, uploadedFileId) {
  return makeRsaAction(
    REMOVE_UPLOADED_FILE,
    `/documents/uploaded-files/${activityOrTaskAssigneeId}/${uploadedFileId}/remove/`,
    'DELETE'
  );
}

export const HIDE_UPLOADED_FILE = makeAsyncActionSet('HIDE_UPLOADED_FILE');
export function hideUploadedFile(activityOrTaskAssigneeId, uploadedFileId) {
  return makeRsaAction(
    HIDE_UPLOADED_FILE,
    `/documents/uploaded-files/${activityOrTaskAssigneeId}/${uploadedFileId}/hide-from-list/`,
    'DELETE'
  );
}

export const CHANGE_FILE_FOLDER = makeAsyncActionSet('CHANGE_FILE_FOLDER');
export function changeFileFolder(activityOrTaskAssigneeId, uploadedFileId, data) {
  return makeRsaAction(
    CHANGE_FILE_FOLDER,
    `/documents/uploaded-files/${activityOrTaskAssigneeId}/${uploadedFileId}/change-folder/`,
    'PUT',
    { folder: data.folder }
  );
}


export const TOGGLE_PRIVACY_UPLOADED_FILE = makeAsyncActionSet('TOGGLE_PRIVACY_UPLOADED_FILE');
export function togglePrivacyUploadedFile(activityOrTaskAssigneeId, uploadedFileId, is_private) {
  return makeRsaAction(
    TOGGLE_PRIVACY_UPLOADED_FILE,
    `/documents/uploaded-files/${activityOrTaskAssigneeId}/${uploadedFileId}/toggle_editable/`,
    'PUT',
    { is_secure_document: is_private }
  );
}

export const TOGGLE_TERMS_ACCEPT = 'TOGGLE_TERMS_ACCEPT';
export function toggleTermsAccept(state) {
  return { type: TOGGLE_TERMS_ACCEPT, payload: state };
}

export const GENERATE_COMPLIANCE_REPORT_PDF = makeAsyncActionSet('GENERATE_COMPLIANCE_REPORT_PDF');
export function generateComplianceReportPdf(filter) {
  return makeRsaAction(
    GENERATE_COMPLIANCE_REPORT_PDF,
    '/reports/compliance-report/',
    'GET',
    filter
  );
}

export const GET_UPLOAD_FORM = makeAsyncActionSet('GET_UPLOAD_FORM');
export function getUploadForm(file, form, field) {
  return makeRsaAction(
    GET_UPLOAD_FORM,
    '/files/',
    'POST',
    {
      filename: file.name
    },
    { form, field }
  );
}

export const UPLOAD_FILE_TO_SIGNED_URL = makeAsyncActionSet('UPLOAD_FILE_TO_SIGNED_URL');
export function uploadFiletoSignedUrl(uploadForm, file) {
  return makeRawRsaAction(
    UPLOAD_FILE_TO_SIGNED_URL,
    uploadForm.url,
    'POST',
    {},
    Object.assign({}, uploadForm.fields, {
      file,
      'Content-Type': file.type
    }),
    null
  );
}

export const COMPLETE_FILE_UPLOAD = makeAsyncActionSet('COMPLETE_FILE_UPLOAD');
export function completeFileUpload(url) {
  return makeRsaAction(
    COMPLETE_FILE_UPLOAD,
    url,
    'POST',
    null,
    null,
    true
  );
}

export const SAVE_CYCLE_RESPONSIBILITIES = makeAsyncActionSet('SAVE_CYCLE_RESPONSIBILITIES');
export function saveCycleResponsibilities(data) {
  return makeRsaAction(
    SAVE_CYCLE_RESPONSIBILITIES,
    '/cycles/cycle-responsibilities/update/',
    'POST',
    data
  );
}

export const UPDATE_DIGITAL_RECORDS = makeAsyncActionSet('UPDATE_DIGITAL_RECORDS');
export function updateDigitalRecord(data, digitalRecordId) {
  return makeRsaAction(
    UPDATE_DIGITAL_RECORDS,
    `/digital-records/${digitalRecordId}/update/`,
    'PUT',
    data
  );
}

export const ARCHIVE_DIGITAL_RECORDS = makeAsyncActionSet('ARCHIVE_DIGITAL_RECORDS');
export function archiveDigitalRecord(digitalRecordId) {
  return makeRsaAction(
    ARCHIVE_DIGITAL_RECORDS,
    `/digital-records/${digitalRecordId}/archive/`,
    'POST',
  );
}

export const UPGRADE_DEMO = makeAsyncActionSet('UPGRADE_DEMO');
export function upgradeDemo() {
  return makeRsaAction(
    UPGRADE_DEMO,
    '/users/upgrade-demo/',
    'POST'
  );
}

export const GET_PRACTICE_REPORT_URL = makeAsyncActionSet('GET_PRACTICE_REPORT_URL');
export function getPracticeReportUrl() {
  return makeRsaAction(
    GET_PRACTICE_REPORT_URL,
    '/admin/practice-report/',
    'GET'
  );
}

export const MARK_TASK_ASSIGNEE_COMPLETE = makeAsyncActionSet('MARK_TASK_ASSIGNEE_COMPLETE');
export function markTaskAssigneeComplete(assigneeId, data) {
  return makeRsaAction(
    MARK_TASK_ASSIGNEE_COMPLETE,
    `/tasks/assignee/${assigneeId}/mark-complete/`,
    'POST',
    data,
    { source: assigneeId }
  );
}

export const TOGGLE_EXPAND_SECTION = 'TOGGLE_EXPAND_SECTION';
export function toggleExpandSection(key, section) {
  return { type: TOGGLE_EXPAND_SECTION, payload: { key, section } };
}

export const COLLAPSE_ALL_SECTIONS = 'COLLAPSE_ALL_SECTIONS';
export function collapseAllSections(key) {
  return { type: COLLAPSE_ALL_SECTIONS, payload: { key } };
}

export const APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES = makeAsyncActionSet('APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES');
export function applyTemplateActivityToLiveCycle (templateActivity, data) {
  return makeRsaAction(
    APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES,
    `/admin/activities/${templateActivity.get('id')}/apply-to-live-cycles/`,
    'POST',
    data
  );
}

export const CHECK_LIVE_TEMPLATE_ACTIVITY_APPLICATION_PROGRESS = makeAsyncActionSet(
  'CHECK_LIVE_TEMPLATE_ACTIVITY_APPLICATION_PROGRESS'
);
export function checkTemplateActivityLiveApplicationProgress (templateActivityId) {
  return makeRsaAction(
    CHECK_LIVE_TEMPLATE_ACTIVITY_APPLICATION_PROGRESS,
    `/admin/activities/${templateActivityId}/apply-to-live-cycles/`,
    'GET'
  );
}

export const CLEAR_ACTIVITY_CYCLE_APPLICATION_PROGRESS = 'CLEAR_ACTIVITY_CYCLE_APPLICATION_PROGRESS';
export function clearActivityCycleApplicationProgress () {
  return {
    type: CLEAR_ACTIVITY_CYCLE_APPLICATION_PROGRESS,
    payload: {},
  };
}

export const CLEAR_ALL_EXPORT_TIMEOUTS = 'CLEAR_ALL_EXPORT_TIMEOUTS';
export function clearAllExportTimeouts () {
  return {
    type: CLEAR_ALL_EXPORT_TIMEOUTS,
  };
}

export const GET_REPORT_URL = makeAsyncActionSet('GET_REPORT_URL');
export function getReportURL(report) {
  return makeRsaAction(
    GET_REPORT_URL,
    `/admin/${report}/`,
    'GET'
  );
}

export const GET_HISTORY_REPORT_URL = makeAsyncActionSet('GET_HISTORY_REPORT_URL');
export function getHistoryReportURL(model, id) {
  return makeRsaAction(
    GET_HISTORY_REPORT_URL,
    `/history/${model}/${id}/report/`,
    'GET'
  );
}

export const GET_PRACTICE_DOCUMENT_VERSIONS = makeAsyncActionSet('GET_PRACTICE_DOCUMENT_VERSIONS');
export function getPracticeDocumentVersions(templateId) {
  return makeRsaAction(
    GET_PRACTICE_DOCUMENT_VERSIONS,
    `/documents/template/${templateId}/practice-adoption/`,
    'GET'
  );
}

export const GET_CYCLE_REPORT_URL = makeAsyncActionSet('GET_CYCLE_REPORT_URL');
export function getCycleReportURL(id) {
  return makeRsaAction(
    GET_CYCLE_REPORT_URL,
    `/admin/cycle-report/${id}/`,
    'GET'
  );
}

export const SET_DOWNLOADING_REPORT = 'SET_DOWNLOADING_REPORT';
export function setDownloadingReport() {
  return { type: SET_DOWNLOADING_REPORT };
}

export const DELETE_TEMPLATE_DOC_ALERTS = makeAsyncActionSet('DELETE_TEMPLATE_DOC_ALERTS');
export function deleteTemplateDocAlerts(docID) {
  return makeRsaAction(
    DELETE_TEMPLATE_DOC_ALERTS,
    `/admin/template-document-versions/${docID.get('id')}/delete-alerts/`,
    'DELETE'
  );
}

export const AUTOSAVE_WORKING_VERSION = makeAsyncActionSet('AUTOSAVE_WORKING_VERSION');
export function autosaveWorkingVersion(data, source_version) {
  return makeRsaAction(
    AUTOSAVE_WORKING_VERSION,
    `/${ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS}/`,
    'POST',
    Object.assign({}, data, {source_version})
  );
}


export const REASSIGN_PRIVATE_DOCUMENTS = makeAsyncActionSet('REASSIGN_PRIVATE_DOCUMENTS');
export function reassignPrivateDocuments(staffPracticeId, data) {
  return makeRsaAction(
    REASSIGN_PRIVATE_DOCUMENTS,
    `/documents/templates/reassign-private/${staffPracticeId}/`,
    'POST',
    data
  );
}

export const EXPORT_DOCUMENT_FOLDER = makeAsyncActionSet('EXPORT_DOCUMENT_FOLDER');
export function exportDocumentFolder(folderId) {
  return makeRsaAction(
    EXPORT_DOCUMENT_FOLDER,
    `/documents/render-folder/${folderId}/`,
    'POST',
    {},
    { folder: folderId }
  );
}

export const MERGE_DOCUMENTS = makeAsyncActionSet('MERGE_DOCUMENTS');
export function mergeDocuments(exports, folder) {
  return makeRsaAction(
    MERGE_DOCUMENTS,
    '/export/merge-pdfs/',
    'POST',
    { exports, folder },
    { exports, folder }
  );
}

export const POLL_EXPORT_STATUS_BULK = makeAsyncActionSet('POLL_EXPORT_STATUS_BULK');
export function pollExportStatusBulk(exports, folder) {
  return makeRsaAction(
    POLL_EXPORT_STATUS_BULK,
    '/export/bulk-status/',
    'POST',
    { exports },
    { exports, folder }
  );
}

export const NO_OP = 'NO_OP';
export function noOp() {
  return { type: NO_OP };
}

export const APP_INIT = 'APP_INIT';
export function appInit() {
  return { type: APP_INIT };
}

export const CHECK_REDIRECTS = 'CHECK_REDIRECTS';
export function checkRedirects(user) {
  return { type: CHECK_REDIRECTS, payload: user };
}

export const FETCH_URL = makeAsyncActionSet('FETCH_URL');
export function fetchURL(url, method = 'GET') {
  return makeRsaAction(
    FETCH_URL,
    url,
    method
  );
}

export const UPDATE_COLLABORATORS = makeAsyncActionSet('UPDATE_COLLABORATORS');
export function updateCollaborators(copiedDocumentId, newCollaboratorIds) {
  return makeRsaAction(
    UPDATE_COLLABORATORS,
    `/documents/copy/${copiedDocumentId}/change-collaborators/`,
    'PUT',
    { collaborators: newCollaboratorIds, }
  );
}

export const GET_CSV_REPORT_URL = makeAsyncActionSet('GET_CSV_REPORT_URL');
export function getCSVReportURL(url, filters = Map()) {
  return makeRsaAction(
    GET_CSV_REPORT_URL,
    url,
    'GET',
    filters.toObject()
  );
}

export const RESTORE_POLICY_AND_OVERVIEW_DOCUMENTS = makeAsyncActionSet('RESTORE_POLICY_AND_OVERVIEW_DOCUMENTS');
export function restorePolicyAndOverviewDocuments(practiceId) {
  return makeRsaAction(
    RESTORE_POLICY_AND_OVERVIEW_DOCUMENTS,
    `/admin/restore-policy-and-overview-documents/${practiceId}/`,
    'DELETE'
  );
}

export const TOGGLE_DOC_TO_READ_CHECKBOX = 'TOGGLE_DOC_TO_READ_CHECKBOX';
export function toggleDocToReadCheckBox(state) {
  return { type: TOGGLE_DOC_TO_READ_CHECKBOX, payload: state };
}

export const TOGGLE_MARK_AS_READ_BUTTON = 'TOGGLE_MARK_AS_READ_BUTTON';
export function toggleMarkAsReadButton(state) {
  return { type: TOGGLE_MARK_AS_READ_BUTTON, payload: state };
}

export const DOCUMENT_VERSION_TO_READ_SEND_REMINDER = makeAsyncActionSet('DOCUMENT_VERSION_TO_READ_SEND_REMINDER');
export function documentVersionToReadSendReminder(documentVersionToRead) {
  return makeRsaAction(
    DOCUMENT_VERSION_TO_READ_SEND_REMINDER,
    `/documents/document-versions-to-read/${documentVersionToRead}/send-reminder/`,
    'POST',
    null,
    { source: documentVersionToRead }
  );
}

export const DOCUMENT_READ_REQUEST_SEND_REMINDER = makeAsyncActionSet('DOCUMENT_READ_REQUEST_SEND_REMINDER');
export function documentReadRequestSendReminder(documentReadRequest) {
  return makeRsaAction(
    DOCUMENT_READ_REQUEST_SEND_REMINDER,
    `/documents/documents-read-requests/${documentReadRequest}/send-reminder/`,
    'POST',
    null,
    { source: documentReadRequest }
  );
}

export const DOCUMENT_READ_REQUEST_DELETE = makeAsyncActionSet('DOCUMENT_READ_REQUEST_DELETE');
export function deleteDocumentReadRequest(documentReadRequestId) {
  return makeRsaAction(
    DOCUMENT_READ_REQUEST_DELETE,
    `/documents/documents-read-requests/${documentReadRequestId}/delete/`,
    'DELETE',
  );
}

export const CREATE_DIGITAL_RECORD_TASK = makeAsyncActionSet('CREATE_DIGITAL_RECORD_TASK');
export function createDigitalRecordTask(data) {
  return makeRsaAction(
    CREATE_DIGITAL_RECORD_TASK,
    '/tasks/',
    'POST',
    data
  );
}

export const CLEAR_DIGITAL_RECORD_TASKS = 'CLEAR_DIGITAL_RECORD_TASKS';
export function clearDigitalRecordsTasks() {
  return {
    type: CLEAR_DIGITAL_RECORD_TASKS,
  };
}

export const DELETE_DIGITAL_RECORD_TASKS = makeAsyncActionSet('DELETE_DIGITAL_RECORD_TASKS');
export function deleteDigitalRecordTasks(staffTasks) {
  const staffTaskIds = staffTasks.map(staffTask => staffTask.get("id"));

  return makeRsaAction(
    DELETE_DIGITAL_RECORD_TASKS,
    '/digital-records/delete-staff-tasks/',
    'POST',
    { staff_task_ids: staffTaskIds }
  );
}


export const UPDATE_WORKING_DRAFT = makeAsyncActionSet('UPDATE_WORKING_DRAFT');
export function updateWorkingDraft(workingDraftId) {
  return makeRsaAction(
    UPDATE_WORKING_DRAFT,
    `/documents/update-working-draft/${workingDraftId}/`,
    'POST',
  );
}

export const RETAIN_LOADED_DOCUMENTS = 'RETAIN_LOADED_DOCUMENTS';
export function setRetainLoadedDocuments(retainLoadedDocumentsBool) {
  return {
    type: RETAIN_LOADED_DOCUMENTS,
    payload: {
      retainLoadedDocuments: retainLoadedDocumentsBool
    }
  };
}

export const CLEAR_ALL_UPDATE_GROUP_TASK_TIMEOUTS = 'CLEAR_ALL_UPDATE_GROUP_TASK_TIMEOUTS';
export function clearAllUpdateGroupTaskTimeouts () {
  return {
    type: CLEAR_ALL_UPDATE_GROUP_TASK_TIMEOUTS,
  };
}

export const STORE_UPDATE_GROUP_TASK_POLL_TIMEOUT = 'STORE_UPDATE_GROUP_TASK_POLL_TIMEOUT';
export function storeUpdateGroupTaskPollTimeout(groupTaskUpdateId, timeout = null) {
  return {
    type: STORE_UPDATE_GROUP_TASK_POLL_TIMEOUT,
    payload: { groupTaskUpdateId, timeout, },
  };
}

export const CLEAR_ALL_BULK_DOC_SEND_READ_REQUEST_TIMEOUTS = 'CLEAR_ALL_BULK_DOC_SEND_READ_REQUEST_TIMEOUTS';
export function clearAllBulkSendReadRequestTimeouts () {
  return {
    type: CLEAR_ALL_BULK_DOC_SEND_READ_REQUEST_TIMEOUTS,
  };
}

export const STORE_BULK_DOC_SEND_READ_REQUEST_POLL_TIMEOUT = 'STORE_BULK_DOC_SEND_READ_REQUEST_POLL_TIMEOUT';
export function storeBulkDocReadRequestPollTimeout(bulkDocSendReadRequestJobId, timeout = null) {
  return {
    type: STORE_BULK_DOC_SEND_READ_REQUEST_POLL_TIMEOUT,
    payload: { bulkDocSendReadRequestJobId, timeout, },
  };
}

export const POLL_UPDATE_GROUP_TASK = makeAsyncActionSet('POLL_UPDATE_GROUP_TASK');
export function pollUpdateGroupTask(groupTaskUpdateId, groupTaskId) {
  return makeRsaAction(
    POLL_UPDATE_GROUP_TASK,
    `/tasks/group-task-update-status/${groupTaskUpdateId}/`,
    'GET',
    null,
    { source: groupTaskUpdateId, groupTaskId }
  );
}

export const POLL_BULK_SEND_READ_REQUEST = makeAsyncActionSet('POLL_BULK_SEND_READ_REQUEST');
export function pollBulkDocSendReadRequest(bulkDocSendReadRequestJobId) {
  return makeRsaAction(
    POLL_BULK_SEND_READ_REQUEST,
    `/documents/document-bulk-send-read_request-job-status/${bulkDocSendReadRequestJobId}/`,
    'GET',
    null,
    { source: bulkDocSendReadRequestJobId }
  );
}

export const STORE_APPLY_TO_LIVE_CYCLES_MONTH_OVERRIDE = 'STORE_APPLY_TO_LIVE_CYCLES_MONTH_OVERRIDE';
export function storeApplyToLiveCyclesMonthOverride(state) {
  return { type: STORE_APPLY_TO_LIVE_CYCLES_MONTH_OVERRIDE, payload: state };
}

export const GET_DIGITAL_RECORD_CHECKLIST = makeAsyncActionSet('GET_DIGITAL_RECORD_CHECKLIST');
export function getDigitalRecordChecklist(recordGroupId, dateId) {
  return makeRsaAction(
    GET_DIGITAL_RECORD_CHECKLIST,
    `/digital-records/checklists/${recordGroupId}/${dateId}/`,
    'GET'
  );
};

export const GET_PREDEFINED_CHECKLIST_FIELDS = makeAsyncActionSet('GET_PREDEFINED_CHECKLIST_FIELDS');
export function getPredefinedChecklistFields(recordType) {
  return makeRsaAction(
    GET_PREDEFINED_CHECKLIST_FIELDS,
    `/digital-records/checklists/record-types/${recordType}/`,
    'GET'
  );
};

export const GET_DIGITAL_RECORD_NAVIGATION_LIST = makeAsyncActionSet('GET_DIGITAL_RECORD_NAVIGATION_LIST');
export function getDigitalRecordNavigationList() {
  return makeRsaAction(
    GET_DIGITAL_RECORD_NAVIGATION_LIST,
    `/digital-records/navigation-list/`,
    'GET'
  );
};

export const UPDATE_DYNAMIC_CHECKLIST_SNAPSHOT = makeAsyncActionSet('UPDATE_DYNAMIC_CHECKLIST_SNAPSHOT');
export function updateDynamicChecklistSnapshot(recordGroupId, dateId) {
  return makeRsaAction(
    UPDATE_DYNAMIC_CHECKLIST_SNAPSHOT,
    `/digital-records/checklists/${recordGroupId}/${dateId}/update-snapshot/`,
    'POST'
  );
};

export const UPDATE_CHECKLIST_RELATED_FILES = makeAsyncActionSet('UPDATE_CHECKLIST_RELATED_FILES');
export function updateChecklistRelatedFiles(recordGroupId, dateId, data) {
  return makeRsaAction(
    UPDATE_CHECKLIST_RELATED_FILES,
    `/digital-records/checklists/${recordGroupId}/${dateId}/update-related-files/`,
    'PATCH',
    data
  );
};

export const ARCHIVE_DIGITAL_RECORD_GROUP = makeAsyncActionSet('ARCHIVE_DIGITAL_RECORD_GROUP');
export function archiveDigitalRecordGroup(recordGroupId) {
  return makeRsaAction(
    ARCHIVE_DIGITAL_RECORD_GROUP,
    `/digital-records/checklists/${recordGroupId}/`,
    'DELETE'
  );
};

export const GET_DIGITAL_RECORD_CHECKLIST_HISTORY = makeAsyncActionSet('GET_DIGITAL_RECORD_CHECKLIST_HISTORY');
export function getDigitalRecordChecklistHistory(recordGroupId, { page = null, shouldAppend = false } = {}) {
  let url = `/digital-records/checklists/${recordGroupId}/history/`;
  if (page) {
    url += `?page=${page}`;
  }
  return makeRsaAction(
    GET_DIGITAL_RECORD_CHECKLIST_HISTORY,
    url,
    'GET',
    null,
    { shouldAppend }
  );
};

export const CREATE_UPDATE_DIGITAL_RECORD_CHECKLIST = makeAsyncActionSet(
  'CREATE_UPDATE_DIGITAL_RECORD_CHECKLIST'
);
export function createUpdateDigitalRecordChecklist(
  recordGroupId,
  dateId,
  data,
  checklistForm,
) {
  return makeRsaAction(
    CREATE_UPDATE_DIGITAL_RECORD_CHECKLIST,
    `/digital-records/checklists/${recordGroupId}/${dateId}/`,
    'PATCH',
    data,
    checklistForm,
  );
}

export const EXCLUDE_TEMPLATE_ACTIVITIES_BY_GROUP = makeAsyncActionSet('EXCLUDE_TEMPLATE_ACTIVITIES_BY_GROUP');
export function excludeTemplateActivitiesByGroup(data) {
  return makeRsaAction(
    EXCLUDE_TEMPLATE_ACTIVITIES_BY_GROUP,
    '/activities/group-set-excluded/',
    'POST',
    data
  );
};

export const CREATE_DOCUMENT_REVIEW = makeAsyncActionSet('CREATE_DOCUMENT_REVIEW');
export function createDocumentReview(adoptedDocumentId, data) {
  return makeRsaAction(
    CREATE_DOCUMENT_REVIEW,
    `/document-reviews/${adoptedDocumentId}/create/`,
    'POST',
    data
  );
}

export const COMPLETE_DOCUMENT_REVIEW = makeAsyncActionSet('COMPLETE_DOCUMENT_REVIEW');
export function completeDocumentReview(adoptedDocumentId, data) {
  return makeRsaAction(
    COMPLETE_DOCUMENT_REVIEW,
    `/document-reviews/${adoptedDocumentId}/complete/`,
    'POST',
    data
  );
}

export const INHERIT_GROUPS_FUNCTIONALITY = makeAsyncActionSet(
  'INHERIT_GROUPS_FUNCTIONALITY'
);
export function inheritGroupsFunctionality(practiceGroupId, practiceId) {
  return makeRsaAction(
    INHERIT_GROUPS_FUNCTIONALITY,
    `/admin/practicegroups/${practiceGroupId}/inherit-groups-functionality/${practiceId}/`,
    'POST'
  );
}

export const GROUP_PUBLISH_NEWS_ITEM = makeAsyncActionSet(
  'GROUP_PUBLISH_NEWS_ITEM'
);
export function groupPublishNewsItem(newsItemId) {
  return makeRsaAction(
    GROUP_PUBLISH_NEWS_ITEM,
    `/news/${newsItemId}/group-publish/`,
    'POST',
    null,
    { collectionName: NEWS }
  );
}

export const GROUP_UNPUBLISH_NEWS_ITEM = makeAsyncActionSet(
  'GROUP_UNPUBLISH_NEWS_ITEM'
);
export function groupUnpublishNewsItem(newsItemId) {
  return makeRsaAction(
    GROUP_UNPUBLISH_NEWS_ITEM,
    `/news/${newsItemId}/group-unpublish/`,
    'POST',
    null,
    { collectionName: NEWS }
  );
}
